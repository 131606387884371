import React from "react"
import ConductorSvg from "../../content/assets/conductor.inline.svg"
import { AddThisInlineFollow } from "./addthis_inline"
import { AdaptiveLink } from '../components/content-elements'

const Footer = () => {

  return (

    <footer>
      <div className="flex flex-wrap items-center py-4 px-4 border-b">
        <div className="w-1/6 text-center"><ConductorSvg /></div>
        <div className="py-4 px-4"><AddThisInlineFollow /></div>
      </div>
      <div className="py-4 text-center">
        <span className="block md:inline-block mb-4 md:mb-0 mx-3">© {new Date().getFullYear()} - Built by {` `}<AdaptiveLink className="text-blue-500 hover:underline" to="https://flutegadgets.co.uk">@flutealot</AdaptiveLink></span>
        <AdaptiveLink className="inline-block mx-4 text-blue-900 hover:text-indigo-600" href="/about">Privacy Policy</AdaptiveLink>
        <AdaptiveLink className="inline-block mx-4 text-blue-900 hover:text-indigo-600" href="/about">Term of Service</AdaptiveLink>
      </div>
    </footer>

  )
}

export default Footer