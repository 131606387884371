import React from "react"
import { useState } from "react"
import tw from "twin.macro"
import { AdaptiveLink, Heading1 } from "../components/content-elements"
import md5 from "md5"

const NavbarSimpleFixed = ({ items, title, mini_logo }) => {

  // console.log("NAV**************************")
  // console.log(items)

  const [hamburgerIsOpen, setHamburgerIsOpen] = useState(false)

  const handleHamburgerShow = (e) => {

    const burgerMenu = document.getElementById("sideMenuHideOnMobile")
    burgerMenu.classList.toggle("-translate-y-full")
    burgerMenu.classList.toggle("mt-12")
    burgerMenu.classList.toggle("shadow")
    burgerMenu.classList.toggle("hidden")
    burgerMenu.classList.toggle("hidden")
    document.getElementById("mobileMenuButtonClose").classList.toggle("hidden")
    document.getElementById("mobileMenuButtonOpen").classList.toggle("hidden")
    // if (hamburgerIsOpen) {
    //   burgerMenu.classList.remove("sm:bg-black")
    //   burgerMenu.classList.add("sm:bg-red-50")
    // } else {
    //   burgerMenu.classList.remove("sm:bg-white")
    //   burgerMenu.classList.add("sm:bg-red-50")
    // }

    return setHamburgerIsOpen(!hamburgerIsOpen)
  }
  //
  // const handleHamburgerHide = (e) => {
  //
  //   // or could do if click anywhere otehr than in menu
  //   // const theElementContainer = document.getElementsByTagName("nav")[0];
  //   // document.addEventListener('click', function(event) {
  //   //   if (!theElementContainer.contains(event.target)) {
  //   // }
  //
  // }

  return (

    <nav
      className="container bg-white shadow fixed z-50 mx-auto inset-x-0 top-0 flex justify-between items-center text-sm md:text-base">

      {/*<!-- I've set max-w-screen-md above, you may need to change it -->*/}
      <AdaptiveLink className="font-extrabold m-3 inline-flex hover:text-primary transition-all duration-600" to="/">

        <img className="pr-4 h-7 all:h-auto" src={mini_logo} alt="" />
        <Heading1>{title}</Heading1>
      </AdaptiveLink>

      <button id="mobileMenuButton" onClick={handleHamburgerShow} className="p-3 focus:outline-none"
              title="Open side menu">

        {/*<!-- SVG For "x" button -->*/}
        {/*<!-- SVG For "x" button -->*/}
        <svg id="mobileMenuButtonClose" className="w-6 h-6 hidden" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>

        {/*<!-- SVG For "Menu burger" button -->*/}
        <svg id="mobileMenuButtonOpen" className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>


      {/*// <!-- List of nav item -->*/}
      <div id="sideMenuHideOnMobile"
           className={`bg-primary text-white md:text-black text-xs lg:text-base md:bg-white font-normal md:font-semibold z-10 rounded-bl-md flex absolute top-0 right-0 transition-all duration-500 transform translate-x-0 w-2/3 xl:w-1/2 md:w-autopx-3 md:px-0 flex-col md:flex-row -translate-y-full md:translate-y-0 md:mt-2 items-center md:mx-0 md:uppercase`}>

        {(() => {
          if (items) {
            return (
              items.map(entry => {
                  const prop = `${entry.label}Nav`
                  const uuid = entry.uuid
                  const label = entry.label
                  if (label) {
                    return (
                      <AdaptiveLink key={md5(uuid)}
                                    activeStyle={tw`md:text-primary-active border-b-2 border-primary-bright font-semibold`}
                                    itemProp={prop} to={uuid}
                                    className="mx-0 sm:mx-2 my-2 border-b-2 border-transparent hover:border-primary-bright hover:text-primary-bright transition-all duration-500 py-1 sm:p-0">{label}</AdaptiveLink>
                    )
                  }
                  return 'Missing'
                }
              )
            )
          }
        })()}


        {/*<AdaptiveLink activeStyle={tw`text-primary-active border-b-2 border-primary-bright`} itemProp={"aboutNav"} to={"/pages/about"}*/}
        {/*              className="mx-0 sm:mx-2 my-2 border-b-2 border-transparent hover:border-primary-bright hover:text-primary-bright transition-all duration-500 py-1 sm:p-0">About</AdaptiveLink>*/}
        {/*<AdaptiveLink activeStyle={tw`text-primary-active border-b-2 border-primary-bright`} itemProp={"blogNav"} to={"/blog"}*/}
        {/*              className="mx-0 sm:mx-2 my-2 border-b-2 border-transparent hover:border-primary-bright hover:text-primary-bright transition-all duration-500 py-1 sm:p-0">Blog</AdaptiveLink>*/}
        {/*<AdaptiveLink activeStyle={tw`text-primary-active border-b-2 border-primary-bright`} itemProp={"galleryNav"} to={"/galleries"}*/}
        {/*              className="mx-0 sm:mx-2 my-2 border-b-2 border-transparent hover:border-primary-bright hover:text-primary-bright transition-all duration-500 py-1 sm:p-0">Galleries</AdaptiveLink>*/}
        {/*<AdaptiveLink activeStyle={tw`text-primary-active border-b-2 border-primary-bright`} itemProp={"contactNav"} to={"/contact"}*/}
        {/*              className="mx-0 sm:mx-2 my-2 border-b-2 border-transparent hover:border-primary-bright hover:text-primary-bright transition-all duration-500 py-1 sm:p-0">Contact</AdaptiveLink>*/}
        {/*<AdaptiveLink activeStyle={tw`text-primary-active border-b-2 border-primary-bright`} itemProp={"testimonialsNav"} to={"/pages/testimonials"}*/}
        {/*              className="mx-0 sm:mx-2 my-2 border-b-2 border-transparent hover:border-primary-bright hover:text-primary-bright transition-all duration-500 py-1 sm:p-0">Testimonials</AdaptiveLink>*/}
        {/*<AdaptiveLink activeStyle={tw`text-primary-active border-b-2 border-primary-bright`} itemProp={"contactNav"} to={"/contact"}*/}
        {/*              className="mx-0 sm:mx-2 my-2 border-b-2 border-transparent hover:border-primary-bright hover:text-primary-bright transition-all duration-500 py-1 sm:p-0">Contact</AdaptiveLink>*/}

      </div>

    </nav>


  )
}
//
//
// export const query = graphql`
//     fragment NavigationFragment on Query {
//         allPages: allMarkdownRemark {
//             nodes {
//                 id
//                 frontmatter {
//                     template
//                     title
//                 }
//                 fields {
//                     collection
//                     slug
//                 }
//             }
//         }
//     }
// `

export default NavbarSimpleFixed