import React from "react"
import Inflector from "inflector-js"
import Navbar from "./navbar_simple"
import Footer from "./footer"

import UseSiteMetadata from "../hooks/use-site-metadata"
import SiteMetadataContext from "../context/SiteMetadataContext"

const Layout = ({ location, children }) => {

  // console.log(UseSiteMetadata())
  const { sitewideMetadata, sitewideMarketing, pagesLookup, pageLabelsLookup, mainMenu, slugsLookup } = UseSiteMetadata()


  // console.log("NAV**************************")
  // console.log(mainMenu)

  const siteTitle = Inflector.camel2words(sitewideMarketing.title) || "** missing site title **"
  const mini_logo = sitewideMarketing.mini_logo

  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = (location !== undefined) ? (location.pathname === rootPath) : false
  const isOnProductionDomain = (location !== undefined) ? (location.hostname === sitewideMetadata.siteUrl) : false

  return (
    <>

      <div
        className={`global-wrapper data-is-root-path=${isRootPath} data-is-on-prod=${isOnProductionDomain} border-solFid border-0 margin-4  md:container md:mx-auto center`}>
        <SiteMetadataContext.Provider value={{
          sitewideMarketing: sitewideMarketing,
          sitewideMetadata: sitewideMetadata,
          pagesLookup: pagesLookup,
          pageLabelsLookup: pageLabelsLookup,
          slugsLookup: slugsLookup,
          mainMenu: mainMenu,
        }}>
          <Navbar location={location} items={mainMenu} title={siteTitle} mini_logo={mini_logo} />

          <main>
            <div className="relative bg-gray-50">
              <div className="relative max-w-7xl mx-auto pt-10 lg:pt-20">

                {children}

              </div>
            </div>
          </main>

          <Footer />
        </SiteMetadataContext.Provider>
      </div>
    </>
  )
}
export default Layout
