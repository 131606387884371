import React from 'react';
import {tag as tagStyle, tags as tagsStyle} from "./style.module.css"
export class TagPreview extends React.Component {
  render() {
    const tags = this.props.value || []
    return React.createElement('ul', {
        className: tagsStyle,
      },
      tags.map(function(value, index) {
        return React.createElement('li', {
          className: tagStyle,
          key: index
        }, value)
      })
    )
  };
}
export default TagPreview