import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

// https://cocky-mccarthy-4f9ef0.netlify.app/markdown-usage/ / https://cocky-mccarthy-4f9ef0.netlify.app/using-props/
import ImageGrid from "./image-grid"

// query MyQuery {
//   folders: allCloudinaryImage {
//     group(field: folder) {
//       totalCount
//       fieldValue
//     }
//   }
//   images: allCloudinaryImage {
//     group(field: folder) {
//       edges {
//         node {
//           id
//         }
//       }
//     }
//   }
// }


const Gallery = ({ folder, columns, orientation }) => (

  <StaticQuery
    query={graphql`
      {
        cloudinaryImage: allCloudinaryImage {
          edges {
            node {
              id
              folder
              thumb
              full
              width
              height
              orientation
              title
              description
              tags
              public_id
              asset_id
            }
          }
        }
      }
    `}


    render={data => (
      <ImageGrid
        folder={folder}
        columns={columns}
        images={ data.cloudinaryImage.edges.filter(image => image.node.folder === folder)}
        orientation={orientation}
      />
    )}
  />

// <StaticQuery
//   query={imageGalleryQuery}
//   render={data => (
//     <ImageGallery
//       folder={folder}
//       columns={columns}
//       data={data}
//       orientation={orientation}
//     ></ImageGallery>
//   )}
// />
)

// const imageGalleryQuery = graphql`
//   query galleryQuery {
//     cloudinaryImage: allCloudinaryImage(filter: {folder: {eq: ""}}) {
//       edges {
//         node {
//           id
//           folder
//           thumb
//           full
//           width
//           height
//           orientation
//           title
//           description
//         }
//       }
//     }
//   }
// `

Gallery.propTypes = {
  folder: PropTypes.string.isRequired
}

export default Gallery