// "use strict";
import React, { useState } from "react"
import PhotoSwipeWrapper from "../../components/photoswipe"
import PropTypes from "prop-types"
import styled from "styled-components"
import Inflector from "inflector-js"

// console.log("*************************************GALLLLERYYYYYYYYYYYYYYYYYYYYYYYYYY")

const getImageSize = (width, height, orientation) => {
  if (orientation === "square") {
    return {
      width: 1200,
      height: 1200
    }
  } else if (orientation === "portrait") {
    // portrait
    const ratio = height / width
    return {
      width: 1200,
      height: parseInt(ratio * 1200, 10)
    }
  } else {
    // landscape
    const ratio = width / height
    return {
      width: parseInt(ratio * 1200, 10),
      height: 1200
    }
  }
}

const imgContainerPadding = orientation => {
  if (orientation === "square") {
    // square
    return "100%"
  } else if (orientation === "portrait") {
    // portrait
    return "130%"
  } else {
    // landscape
    return "70%"
  }
}

const renderImageGridItem = (img, galleryItems, orientation, index, handleOpen) => {
  // console.log(img)
  // console.log(index)
  const imgOrientation = orientation ? orientation : img.node.orientation
  const imgSize = getImageSize(img.node.width, img.node.height, img.node.orientation)
  const containerPadding = imgContainerPadding(imgOrientation)
  const imgTitle = img.node.title ? img.node.title : Inflector.humanize(img.node.public_id)

  // console.log(imgTitle)

  return React.createElement(ImageGridItem, {
    onClick: () => handleOpen(index),
    "data-size": `${imgSize.width}x${imgSize.height}`,
    key: img.node.id,
    thumb: `${img.node.thumb}`,
    //full: `${img.node.full}`,
    folder: `${img.node.folder}`,
    title: imgTitle,
    description: `${img.node.description}`,
    orientation: imgOrientation,
    padding: containerPadding,
    itemProp: "contentUrl"
  })
}

const renderRows = (columns, images, galleryItems, orientation, handleOpen) => {
  let counter = 0
  return columns.map((row, index) => {
    if (index !== 0) {
      counter = counter + columns[index - 1]
    }
    // console.log(`counter ${counter}`)
    // console.log(`row ${row}`)
    const rowImages = images.slice(counter, counter + row)
    return React.createElement(ImageGridRow, {
      columns: row,
      key: counter,
      imgCount: row
    }, rowImages.map(img => {
      const index = galleryItems.findIndex(item => item.nodeId === img.node.id)
      // console.log(galleryItems)
      // console.log(`filename ${img.node.id}`)
      // console.log(`index ${index}`)
      return renderImageGridItem(img, galleryItems, orientation, index, handleOpen)
    }))
  })
}

const getListOfGalleryItems = (images, imagesVisibleCount) => {
  let items = images.map(image => {

    let imgTitle = image.node.title || Inflector.humanize(image.node.public_id)
    imgTitle = image.node.description ? `${imgTitle}<br />${image.node.description}` : imgTitle

    return {
      nodeId: image.node.id,
      src: `${image.node.full}`,
      msrc: `${image.node.thumb}`,//since this is loaded on thumbnail display, efficient to use here
      w: image.node.width,
      h: image.node.height,
      title: `${imgTitle}`
      // author: 'ANOTHER TEST'
    }
  })

  if (imagesVisibleCount && items.length > imagesVisibleCount) {
    items = items.slice(0, imagesVisibleCount)
  }

  return items
}

const isOdd = num => num % 2

const ImageGrid = ({
                     folder,
                     columns,
                     images,
                     orientation
                   }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(0)

    const handleOpen = index => {
      setIsOpen(true)
      setIndex(index)
    }

    const handleClose = () => {
      setIsOpen(false)
    }

  let defaultImageGridColumns = 2
    let columnsPerRow
    let galleryItems
    // because its a static query, we actually have ALL images here!
  if (isOdd(images.length) === 1) {
    defaultImageGridColumns = 3
  }

  if (columns && columns.length > 0) {
    columnsPerRow = columns.split(",").map(Number)
    const imagesVisibleCount = columnsPerRow.reduce((partialSum, a) => partialSum + a)
    console.log (imagesVisibleCount)
    console.log (images.length)
    galleryItems = getListOfGalleryItems(images, imagesVisibleCount)
  } else {
    galleryItems = getListOfGalleryItems(images)
  }
  return (
     React.createElement(React.Fragment, {},
      React.createElement(ImageGridWrapper, {},
        (columns && columns.length > 0)
          ? React.createElement(React.Fragment, {}, renderRows(columnsPerRow, images, galleryItems, orientation, handleOpen))
          : React.createElement(ImageGridRow, { columns: defaultImageGridColumns },
          images.map((img, index) => renderImageGridItem(img, galleryItems, orientation, index, handleOpen)))),
          <PhotoSwipeWrapper isOpen={isOpen} index={index} items={galleryItems} onClose={handleClose} />
    )
  )
}

const ImageGridWrapper = styled.div`
  display: block;
`
const ImageGridRow = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
  margin-bottom: 1em;
`
const ImageGridItem = styled.a`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.thumb})`};
  box-shadow: 5px 5px 2px #888888;
  padding-bottom: ${props => props.padding};
  display: inline-block;
  width: 100%;
  &:hover {
    box-shadow: 6px 6px 3px #6f6f6f;
    cursor: pointer;
    
  }
`

ImageGrid.propTypes = {
  folder: PropTypes.string.isRequired
}

export default ImageGrid