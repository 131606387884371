import React from "react"
import { useEffect  } from "react"
import {
  ArticleBodyActionHeading
} from "../components/content-elements"


export function AddThisInlineFollow() {

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.addthis.layers.refresh()
  //   }, 200)
  //   //setCount(count + 1)
  // }, [])

  return (
    <div className="py-4 text-center border-1">
      <div className="addthis_inline_follow_toolbox" />
    </div>
  )

}

export function AddThisInlineShare({heading}) {
  const headingText = heading || 'Share'
  // const [count, setCount] = useState(0)
// class AddThisInlineShare extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { addThisLoaded: false }
//   }
//
//   componentDidMount() {
//
//       //window.addthis.init()
//       window.addthis.layers.refresh() // important! init the add this widget
//       //addthis.update("share", "url", "my-initial-url") // update with initial prop value
//     })
//   }
  //
  // componentDidUpdate()
  // {
  //   window.addthis.layers.refresh()
  //   //addthis.update("share", "url", this.props.myurl) // update with prop value
  // }

  // componentWillReceiveProps(nextProps)
  // {
  //   window.addthis.layers.refresh()
  //   //addthis.update("share", "url", nextProps.myurl) // update with prop value
  // }

  // componentDidMount()
  // {
  //   window.addthis.layers.refresh();
  // }

  useEffect(() => {
    setTimeout(() => {
      window.addthis.layers.refresh()
    }, 500)
    //setCount(count + 1)
  }, [])


  return (
    <div className="py-4 text-center align-items-center">
      <ArticleBodyActionHeading>{headingText}</ArticleBodyActionHeading>
      {/*<span className="inline-block mb-4 md:mb-0 mx-3"></span>*/}
      <div className="addthis_inline_share_toolbox"></div>
    </div>

  )

}