import { useStaticQuery, graphql } from "gatsby"

const UseSiteMetadata = () => {


  const { marketing, homepage, metadata, allPageNodes } = useStaticQuery(graphql`

              fragment ConfigFields on Query {
                  metadata: site {
                      siteMetadata {
                          title
                          description
                          siteUrl
                      }
                  }
              }

              fragment MarketingFields on MarkdownRemark {
                  frontmatter {
                      hero_logo
                      mini_logo
                      social_twitter
                      social_facebook
                      social_instagram
                      social_giving
                      highlighted_content {
                          type
                          headline
                          teaser
                          image
                          page_link
                          blog_link
                          dynamic_page_link
                      }
                  }
              }

              fragment SEOFields on MarkdownRemark {
                  frontmatter {
                      title
                      description
                  }
              }

              fragment AllPages on Query {
                  allPageNodes: allSitePage(
                      filter: {path: {ne: "/dev-404-page/"}, context: {collection: {in: ["pages", "dynamic-pages", "blog"]
                      }}}
                  ) {
                      nodes {
                          path
                          context {
                              uuid
                              collection
                              slug
                              label
                              show_on_menu
                          }
                      }
                  }

                  #          allPageNodes: allMarkdownRemark {
                  #              nodes {
                  #                  id
                  #                  frontmatter {
                  #                      template
                  #                      title
                  #                  }
                  #                  fields {
                  #                      collection
                  #                      slug
                  #                  }
                  #              }
                  #          }
              }

              query SiteMetaData {
                  marketing: markdownRemark(fields: {slug: {eq: "/_settings/sitewide/"}}) {
                      frontmatter {
                          menu
                      }
                      ...SEOFields
                      ...MarketingFields
                  }
                  homepage: markdownRemark(fields: {slug: {eq: "/pages/home/"}}) {
                      frontmatter {
                          uuid: id
                          label: menu_label
                          show_on_menu: menu_show
                      }
                  }
                  ...ConfigFields
                  ...AllPages
              }
    `
  )

  const allPagesLookup = []
  const allSlugsLookup = []
  const allPageLabelsLookup = []
  const allPageShowOnMenuLookup = []

  allPagesLookup[homepage.frontmatter.uuid] = "/"
  allPageLabelsLookup[homepage.frontmatter.uuid] = homepage.frontmatter.label
  allPageShowOnMenuLookup[homepage.frontmatter.uuid] = homepage.frontmatter.show_on_menu ?? false

  allPageNodes.nodes.forEach(entry => {
    allPagesLookup[entry.context.uuid] = entry.context.slug
    allSlugsLookup[entry.context.slug] = entry.context.uuid
    allPageLabelsLookup[entry.context.uuid] = entry.context.label
    allPageShowOnMenuLookup[entry.context.uuid] = entry.context.show_on_menu ?? false
  })

  const mainMenu = []
  let i = 0
  marketing.frontmatter.menu.forEach(el => {
    let show = allPageShowOnMenuLookup[el] ?? false
    if (show) {
      mainMenu[i] = { label: allPageLabelsLookup[el], uuid: el }
    }
    i++
  })
// until we have a nice way to combine two collections into one sortable
  allPageNodes.nodes.forEach(el => {
    var seen = marketing.frontmatter.menu.includes(el.context.uuid)
    if (!seen && allPageShowOnMenuLookup[el.context.uuid]) {
      mainMenu[i] = { label: el.context.label, uuid: el.context.uuid }
      i++
    }
  })
  // console.log("PPPPPPPPPPPPP")
  // console.log(marketing.frontmatter.menu)

  return ({
    sitewideMarketing: marketing.frontmatter,
    sitewideMetadata: metadata.siteMetadata,
    sitewidePages: allPageNodes,
    pagesLookup: allPagesLookup,
    slugsLookup: allSlugsLookup,
    pageLabelsLookup: allPageLabelsLookup,
    mainMenu: mainMenu
  })
}

export default UseSiteMetadata
