import styled from "styled-components"
//https://github.com/ben-rogerson/twin.macro
import tw from "twin.macro"
import React from "react"
import md5 from "md5"
import { Link as GatsbyLink } from "gatsby"
// import { Listing as ListingComponent } from "./listing"
// import { AddThisInlineShare } from "./addthis_inline"
import rehypeReact from "rehype-react"
import Gallery from "./galleryWidget/gallery"
import SEO from "./seo"
import SiteMetadataContext from "../context/SiteMetadataContext"
import Slider from "react-slick"
import TagPreview from "../components/tagWidget/TagPreview"


// @todo
//   <!--  Labels by colour -->
// <a href="#" class="inline-block rounded-full text-white
// <a href="#" class="inline-block rounded-full text-white
// bg-black hover:bg-gray-500 duration-300
// text-xs font-bold
// mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1
// opacity-90 hover:opacity-100">
// Black Label
// </a>

// https://tailwindcomponents.com/component/buttons-shapes-variants

export const Button = styled("button")`
  ${tw`font-mono text-sm text-violet-600 hover:text-violet-300`};
`

export const NextPrevButton = tw.button`
  uppercase px-8 py-2 rounded-full bg-gray-300 text-gray-600 max-w-max shadow-sm hover:shadow-lg
`

export const TagPill = tw.span`
 absolute text-sm py-1 px-3 -m-2 text-white font-semibold bg-primary-bright rounded-full z-30
`

// "mt-3 text-lg leading-6 text-gray-500"
export const Paragraph = tw.p`
  mt-3 mb-6 text-sm leading-6 text-gray-600 antialiased md:text-base  leading-tight prose
 
`

// "mt-3 text-lg leading-6 text-gray-500"
export const TeaserParagraph = tw.p`
  mt-3 mb-6 text-sm leading-6 text-gray-600 antialiased md:text-sm lg:text-base leading-tight line-clamp-3 md:line-clamp-6 prose

`

export const Heading1 = tw.h2`
 prose text-primary text-xl sm:text-xl md:text-2xl font-semibold tracking-tight leading-tight
`

//  "text-5xl mb-6 leading-tight font-semibold font-heading"
// text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl
export const Heading2 = tw.h2`
 prose text-2xl sm:text-3xl md:text-4xl font-semibold tracking-tight leading-tight
`

export const Heading3 = tw.h3`
  text-xl sm:text-2xl md:text-3xl font-semibold tracking-tight leading-tight  prose
`

export const Heading4 = tw.h4`
  text-base sm:text-lg md:text-lg font-semibold tracking-tight leading-tight prose
`

export const ArticleBodyActionHeading = tw.h4`
  text-base sm:text-lg md:text-lg font-semibold tracking-tight leading-tight prose max-w-full
`

export const ArticleBody = tw.div`
  mt-3 text-sm leading-6 text-gray-600 antialiased md:text-lg leading-tight prose
`

export const MinorColumn = tw.section`
  bg-gray-200 py-5 px-4 sm:px-6 container lg:col-span-2 lg:px-8 lg:py-5 xl:pr-12 order-1 lg:order-none  bg-gradient-to-t from-gray-300 to-gray-50
`

export const MajorColumn = tw.section`
  bg-red-200 py-5 px-4 sm:px-6 container lg:col-span-3 lg:px-8  lg:py-5 xl:pl-12 bg-gradient-to-t from-gray-300 to-gray-100 border-t-8 border-primary
`

export const FlexWrapMobile = tw.div`
  flex flex-wrap bg-transparent col-span-5 lg:flex-none lg:relative max-w-7xl mx-auto lg:grid lg:grid-cols-5
`

export const FullWidthColumn = tw.section`
  bg-gray-100 py-5 px-4 sm:px-6 lg:col-span-5 lg:py-10 lg:px-8 xl:pl-12  bg-gradient-to-t from-gray-200 to-gray-50 border-t-8 border-primary
`

export function PromoSlides({ items, sliderSettings }) {
  return (
    <Slider {...sliderSettings}>
      {(() => {
        // if (items) {
        return (
          items.map(entry => {

              // reject empty links
              const actions = [entry.page_link, entry.dynamic_page_link, entry.blog_link].filter(function(value) {
                return (value !== null)
              })
              // console.log(actions)
              const actionArray = actions.map((action) => {
                return { to: action }
              })
              // console.log(actionArray)

              return (
                <PromotedContentWithImage
                  key={md5(entry.headline)}
                  headline={entry.headline}
                  teaser={entry.teaser}
                  image={entry.image}
                  actions={actionArray}
                />
              )

            }
          )
        )
        // }
      })()}
    </Slider>
  )
}

// # https://daily-dev-tips.com/posts/responsive-header-in-tailwind-css/
export function PromotedContentWithImage({ headline, teaser, image, actions }) {
  return (

    <div className="relative h-72 m-8 overflow-hidden rounded-lg">

      <div className="absolute z-30 flex w-full h-full">
        {/*<div className="relative z-30 w-5/6 px-6 py-8 text-white md:py-10 md:w-1/2">*/}
        {/*  <Heading3><span className="text-white">Tailwind responsive header</span></Heading3>*/}
        {/*  <span></span>*/}
        {/*</div>*/}
        <div className="absolute top-0 right-0 flex w-full h-full">
          <div className="w-1/2 md:w-2/5 h-full bg-primary bg-gradient-to-l from-primary to-transparent">
            <AdaptiveLink to={(actions.length ? actions[0].to : undefined)}>
              <div className="absolute z-30  px-6 py-8 pr-1/4 text-white md:py-10 h-auto w-3/5 md:w-5/12">
                <Heading3><span
                  className="text-white bg-primary-dull bg-opacity-25 md:bg-none">{headline}</span></Heading3>
                <TeaserParagraph><span
                  className="text-white bg-primary-dull bg-opacity-25 md:bg-none line-clamp-4 md:line-clamp-6">{teaser}</span></TeaserParagraph>

              </div>
            </AdaptiveLink>
            <div className="absolute bottom-0 right-0 flex z-40">
              <ActionButtons actions={actions} />
            </div>


          </div>

          <div className="relative w-1/3  bg-gradient-to-r from-primary to-transparent">
            {/*<svg*/}
            {/*  fill="currentColor"*/}
            {/*  viewBox="0 0 100 100"*/}
            {/*  className="absolute inset-y-0 z-20 h-full text-primary opacity-50 from-transparent"*/}
            {/*>*/}
            {/*  <polygon id="diagonal" points="0,0 100,0 50,100 0,100"></polygon>*/}
            {/*</svg>*/}
            {/*<svg*/}
            {/*  fill="currentColor"*/}
            {/*  viewBox="0 0 100 100"*/}
            {/*  className="absolute inset-y-0 z-10 h-full ml-6 text-white opacity-20"*/}
            {/*>*/}
            {/*  <polygon points="0,0 100,0 50,100 0,100"></polygon>*/}
            {/*</svg>*/}

          </div>
        </div>
      </div>

      <div className="absolute top-0 right-0 block w-9/12 h-full">
        <img
          alt=""
          className="object-cover min-w-full h-full"
          src={image}
        />
      </div>

    </div>
  )
}

export function HeroSection({ headline, body, image, actions }) {

  return (

    <>

      <section className="md:flex p-8 md:p-0">
        {/*// <!-- w-32 h-32 md:w-48 md:h-auto -->*/}

        <img className="w-32 md:w-1/2 md:h-1/2 md:height-auto lg:w-1/4 mx-auto" src={image}
             alt="" />
        <div className="md:px-8 text-center md:text-left space-y-4 order-1 lg:order-none">
          <Heading2 itemProp="headline">{headline}</Heading2>
          <Paragraph>
            {body}
          </Paragraph>
          <div className={`items-center text-center lg:text-left`}>
            <ActionButtons actions={actions} />
          </div>
        </div>
      </section>
    </>
  )
}


export function ActionButtons({ actions }) {

  return (
    <div>{
      actions.map((action) => {
        const itemKey = md5(action.to + action.label)
        // console.log(`Navigate to = ${action.to}`)
        return <AdaptiveLink key={itemKey} to={action.to.toString()}
                             className={"inline-block py-2 px-2 lg:py-4 lg:px-8 leading-none text-white border-dotted border border-primary-bright bg-primary hover:bg-primary-bright hover:text-white font-semibold rounded shadow underline"}>{action.label}</AdaptiveLink>
      })
    }</div>
  )

}

export const InlineLink = ({ children, to, ...other }) => {
  return (<AdaptiveLink to={to} children={children} className={`text-indigo-500`} />)
}


// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export const AdaptiveLink = ({ children, to, label, activeClassName, partiallyActive, ...other }) => {
  // const { pagesLookup } = UseSiteMetadata()
  // console.log(pagesLookup)

  // # convert content ID-based links into current slugs
  // const transformedTo = pagesLookup[to?.toString()]
  // const key = to //'page-1615638305901-P7IvrHVHR'
  // console.log(`keys = ${Object.keys(pagesLookup)}`)
  // console.log(Object.keys(pagesLookup)[0])
  // console.log(`${to} => ${transformedTo} => ${pagesLookup[Object.keys(pagesLookup)[0]]}`)


  // Use Gatsby Link for internal links, and <a> for others
  return (

    <SiteMetadataContext.Consumer>
      {({ pagesLookup, pageLabelsLookup }) => {

        // console.log(slugsLookup)
        // console.log(pagesLookup)

        // if (to===undefined) {
        //   return <> </>
        // }

        // const md5to = to ? md5(to) : 'why undefined'
        const mutatedTo = to ? pagesLookup[to] : null
        const finalTo = mutatedTo ?? to
        const mutatedLabel = label ?? pageLabelsLookup?.[to] ?? "Read More..."
        // if (to == "page-1615638086636-ue3XsLY10") {
        //   console.log(`${to} ${mutatedTo} ${finalTo} ${mutatedLabel}`)
        //   console.log(pagesLookup)
        // }
        const mutatedChildren = children ?? mutatedLabel

        const internal = /^\/(?!\/)/.test(finalTo)
        if (internal) {
          // if (to == "page-1615638086636-ue3XsLY10") {
          //   console.log('internal')
          //   console.log(finalTo)
          // }
          return (
            <GatsbyLink
              to={finalTo}
              activeClassName={activeClassName}
              partiallyActive={partiallyActive}
              {...other}
            >
              {mutatedChildren}
            </GatsbyLink>
          )
        } else {
          return (
            <a href={to} {...other}>
              {mutatedChildren}
            </a>
          )
        }
      }}

    </SiteMetadataContext.Consumer>
  )

}


// Generic CMS Page Top matter
export const Page = ({ page, seo, children }) => {

  // console.log(page)
  const doSeo = (seo && seo === true) || false

  const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: {
        "photo-gallery": Gallery
        //p: Paragraph
      }
    }
  ).Compiler

  return (

    <article itemScope itemType="http://schema.org/Article">

      {(() => {
        if (doSeo) {
          return (
            <SEO
              title={page.frontmatter.title}
              description={page.frontmatter.description || page.excerpt}
            />
          )
        }
      })()}

      <section>
        <header>

          {(
            page.frontmatter.image ?
              <img key={page.frontmatter.image} className="w-auto w-full min-h-0" src={page.frontmatter.image}
                   alt="" /> : ""
          )}


          <Heading2 itemProp="headline">{page.frontmatter.heading || page.frontmatter.title}</Heading2>
          {(() => {
            if (page.frontmatter.subheading || page.frontmatter.subtitle) {
              return (<Heading3>{page.frontmatter.subheading || page.frontmatter.subtitle}</Heading3>)
            }
          })()}

          {(() => {
            if (page.frontmatter.date) {
              return (<Paragraph>{page.frontmatter.date || (new Date().toString())}</Paragraph>)
            }
          })()}

        </header>
      </section>

      {(() => {
        if (page.frontmatter.tags) {
          return (<section itemProp="articleTags">
            <TagPreview value={page.frontmatter.tags} />
          </section>)
        }
      })()}

      <section itemProp="articleBody">
        <ArticleBody>
          {renderAst(page.htmlAst)}
        </ArticleBody>
      </section>

      {children}

    </article>
  )

}